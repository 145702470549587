.error-page {
    margin: 10rem auto;
    max-width: 70rem;
    position: relative;
    padding: 0 1rem;
    h1 {
        font-size: 4.5rem;
        font-weight: bold;
        margin-bottom: .5rem;
    }

    h2 {
        font-size: 1.7rem;
        font-weight: 600;
        margin-bottom: .5rem;
    }

    img {
        max-width: 100%;
        height: auto;
        width: 20rem;
    }

    @media (max-width: 991px) {
        margin: 4rem auto;
        img {
            width: 15rem;
        }
    }

    a {
        display: inline-block;
        padding: .7rem 1.5rem;
        font-size: 1.1rem;
        color: #ffffff;
        margin-top: 1rem;
        transition: all .5s;
        border: 1px solid #ffffff;
        background: transparent;

        &:hover {
            background: #fff;
            color: #000;
            text-decoration: none;
        }
    }
}
